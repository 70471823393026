<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<h4>Muestras</h4>
					</template>
					<template v-slot:right>
						<Button label="Exportar a Excel"  class="p-button-warning  p-mr-2" v-on:click="download" />
						<Button label="Autorizar Descuento..." v-show="VisibleAuto" icon="pi pi-dollar" class="p-button-raised p-button-warning p-mr-2" @click="ShowAutoDialog" :disabled="autoriza"  />
						<Button label="Solicitar Descuento..." icon="pi pi-dollar" class="p-button-raised p-button-warning p-mr-2" @click="openNewdescuento" :disabled="!selectedMuestras || !selectedMuestras.length"  />
                        <!-- <Button label="Cancelar Muestra" icon="pi pi-trash" class="p-button-raised p-button-danger p-mr-2" @click="confirmDeleteSelected" :disabled="!selectedMuestras || !selectedMuestras.length" /> -->
						<Button label="Reimprimir Etiquetas" icon="pi pi-print" class="p-button-raised p-button-secondary p-mr-2" @click="confirmPrintSelected" :disabled="!selectedMuestras || !selectedMuestras.length" />
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="muestras" @click="rowClicked" v-model:selection="selectedMuestras" :key="estadoKey" dataKey="id_muestra" :paginator="true" :rows="10" :filters="filters" class="p-datatable-striped" 
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords} muestras">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Listado de Muestras</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>

					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="id_muestra" header="Folio" :sortable="true"></Column>
                    <Column field="fecha_solicitud" header="Solicitud" :sortable="true">
						<template #body="slotProps">
                                    {{computedDatesFormat(slotProps.data.fecha_solicitud)}}
                        </template>
					</Column>
					<Column field="doctor" header="Doctor" :sortable="true"></Column>
					<Column field="Name" header="Paciente" :sortable="true"></Column>
					<Column field="servicio" header="Servicio" :sortable="true"></Column>
					<Column field="porc_descuento" header="Descuento" :sortable="true"></Column>
					<Column field="AutoUsua" header="Autorizado Por" :sortable="true"></Column> 
					<Column field="estatu" header="Estatus" :sortable="true"></Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-mr-2" @click="confirmDeleteMuestra(slotProps.data)" />
							<Button icon="pi pi-eye" class="p-button-rounded p-button-success" @click="confirmViewMuestra(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="deleteMuestraDialog" :style="{width: '450px'}" header="Confirmar" :modal="true"  class="p-fluid">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="muestra">¿Desea cancelar la muestra <b>{{muestra.clave}}</b>?</span>
					</div>
					<div class="p-field" style="margin-top:1rem">
						<label for="cancelacion" >Motivo de cancelación *</label>
						<InputText id="cancelacion" v-model.trim="cancelacion" required="true" autofocus />						 
					</div>
					 
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteMuestraDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteMuestra" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteMuestrasDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="muestra">¿Desea cancelar las muestras seleccionadas?</span>
					</div>
					<!-- <div class="p-field">
						<label for="cancelacion">Motivo de cancelación *</label>
						<InputText id="cancelacion" v-model.trim="cancelacion" required="true" autofocus />						 
					</div> -->
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteMuestrasDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteSelectedMuestras" />
					</template>
				</Dialog>

				<Dialog v-model:visible="printMuestrasDialog" :style="{width: '450px'}" header="Reimprimir" :modal="true">
					<!-- <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="muestra">¿Desea imprimir las etiquetas  de las muestras seleccionadas?</span>
					</div> -->

						 <div class="p-field p-col-12 p-md-12 p-grid">
                               
                                <div class="p-field p-col">
                                    <label for="grande">No. de Etiquetas exteriores</label>
                                    <InputNumber id="grande" inputStyle="text-align: center;" v-model="etiquetasExteriores" integeronly />
                                </div>
                                <div class="p-field p-col" >
                                    <label for="chica">No. de Etiquetas en tubos</label>
                                    <InputNumber  id="chica" inputStyle="text-align: center;" v-model="etiquetasTubos" integeronly />
                                </div>
								 
                            </div>
							<div class="p-field p-col-12 p-md-12 p-grid">
								<div class="p-field p-col">
									  <label for="chica">Servicio</label>
									<Dropdown id="paciente" v-model="SelectServicosImprimir" :options="ServicosImprimir" optionLabel="Nombre" style="width:95%"></Dropdown>
								</div>
							</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="printMuestrasDialog = false"/>
						<Button label="Imprimir" icon="pi pi-check" class="p-button-text" @click="printSelectedMuestras" />
					</template>
				</Dialog>

				 <Dialog v-model:visible="descuentoDialog" :style="{width: '450px'}" header="Solicitar Descuento" :modal="true" class="p-fluid">
					<div class="p-field">
						<label for="desc">% Descuento *</label>
                        <InputNumber id="desc" v-model="porcdescuento" integeronly required="true" :min="0" :max="100" autofocus :class="{'p-invalid': submitted && !porcdescuento} " />
						<small class="p-invalid" v-if="submitted && !porcdescuento">El descuento es requerido</small>
					</div>

					<div class="p-field">
						<label for="doctordescuento">Doctor que solicita el descuento *</label>
						<Dropdown id="doctordescuento" name="doctordescuento" v-model="doctordescuento" :options="doctores.filter(d=> d.socio === 1)" optionLabel="nombre" placeholder="Selecionar un doctor ..."  autofocus :class="{'p-invalid': submitted && !doctordescuento}" />
						<small class="p-invalid" v-if="submitted && !doctordescuento">El doctor es requerido</small>
					</div>

					<div class="p-field">
						<label for="motivodescuento">Motivo del descuento *</label>
						<InputText id="motivodescuento" v-model.trim="motivodescuento" required="true" autofocus :class="{'p-invalid': submitted && !motivodescuento}" />
						<small class="p-invalid" v-if="submitted && !motivodescuento">El motivo de descuento es requerido</small>
					</div>

					<!-- <div class="p-field">
						<label for="comentarios">Comentarios</label>
						<InputText id="comentarios" v-model.trim="comentarios" />
					</div> -->

					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Solicitar Descuento" icon="pi pi-check" class="p-button-text" @click="saveDescuento" />
					</template>
				</Dialog>

				 <Dialog v-model:visible="confirmViewMu" :style="{width: '650px'}" header="Muestra" :modal="true" class="p-fluid">
					<div class="p-field">
						<div class="p-grid" style="margin-top: 5px">
							<div class="p-col-4">
								<label for="desc">Folio de la Muestra</label>
                        		<InputText :disabled="true"  id="desc" v-model="FolioM" autofocus  />	
							</div>
							<div class="p-col-8">
								<label for="desc">Doctor</label>
                        		<InputText :disabled="true"  id="desc" v-model="DoctorM" autofocus  />	
							</div>							
						</div>
						<div class="p-grid">
							<div class="p-col-8">
								<label for="desc">Paciente</label>
                        		<InputText :disabled="true"  id="desc" v-model="PacienteM" autofocus  />	
							</div>
							<div class="p-col-4">
								<label for="desc">Fecha Nacimiento</label>
                        		<InputText :disabled="true"  id="desc" v-model="NacimientoM" autofocus  />	
							</div>							
						</div>
						
						<div class="p-grid">
							<div class="p-col-12">
								<label for="desc">Antecedentes</label>
                        		<InputText :disabled="true"  id="desc" v-model="AntecedentesM" autofocus  />	
							</div>							 					
						</div>
						<div class="p-grid">
							<div class="p-col-4">
								<label for="desc">Fecha deseada de toma</label>
                        		<InputText :disabled="true"  id="desc" v-model="FechaDeseadaTomaM" autofocus  />	
							</div>							 					
						</div>
						<div class="p-grid">
							<div class="p-col-12">
								<label for="desc">Padecimientos</label>
                        		<DataTable ref="dt" :value="Padecimientos" :key="solicitudmuestraKey" dataKey="nombre" :paginator="false">
									<Column field="nombre" header="Nombre" :sortable="true"></Column>									 
								</DataTable>
							</div>							 					
						</div>
						<div class="p-grid">
							<div class="p-col-12">
								<div style="display:flex;justify-content:space-between;">
									<label for="desc">Servicios</label>
									<button type="button" class="btn" @click="displayServices"> <i :class="displayedServices?'pi pi-times':'pi pi-pencil'"></i></button>
								</div>
								<DataTable ref="dt" :value="displayedServices?serviciosdisponibles:servicios" :key="solicitudmuestraKey" dataKey="nombre" :paginator="false" v-model:selection="serviciosSeleccionados" @click="compareServices">
										<!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
									<Column :selectionMode="displayedServices?'multiple':''" headerStyle="width: 0rem"></Column>
									<Column field="nombre" header="Servicio" :sortable="true" :hidden="true"></Column>
									<Column field="precio" header="Precio" :sortable="true">
										<template #body="slotProps">
											${{formatCurrency(slotProps.data.precio)}}
										</template>
									</Column>
								</DataTable>
							</div>							 					
						</div>
											 
					</div>	
					 		 

					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="hideDialog1"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveServicios" v-show="changeService && displayedServices"/>						
					</template>
				</Dialog>

				<Dialog v-model:visible="AutorizardesDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span >¿Desea autorizar el descuento?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="hideAutoDialog"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="Autodescuento" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import MuestraService from '../service/MuestraService';
import SolicitudMuestraService from '../service/SolicitudMuestraService';
import DoctorService from '../service/DoctorService';
import UsuarioService from '../service/UsuarioService';
import ServicioService from '../service/ServicioService';
import Cookies from "js-cookie"
window.Cookies = Cookies
 import moment from 'moment';
import XLSX from 'xlsx'
import mqtt from 'mqtt'

export default {
	data() {
		return {
			etiquetasExteriores:1,
                etiquetasTubos:1,
			muestras: null,
			AutorizardesDialog:false,
			muestraDialog: false,
			deleteMuestraDialog: false,
			deleteMuestrasDialog: false,
			printMuestrasDialog: false,
			muestra: {},
			selectedMuestras: null,
			filters: {},
			submitted: false,
			estadoKey: 0,
			porcdescuento: 0,
			doctores: null,
			doctordescuento:null,
			descuentoDialog: false,
			DescuentoPost:[],
			motivodescuento: null,
			EstatusCambio:[],
			cancelacion:null, 
			confirmViewMu:false,
			FolioM:null,
			DoctorM:null,
			PacienteM:null,
			NacimientoM:null,
			PadecimientosM:'',
			AntecedentesM:null,
			FechaDeseadaTomaM:null,
			servicios:null,
			serviciosdisponibles:null,
			serviciosSeleccionados:null,
			displayedServices:false,
			changeService:false,
			Padecimientos:null,
			autoriza:true,
			AutoPos:[],
			ImpPost:[], 
			useconver:null,
			userdata:null,
			permi:null,
			userdatapermisos:null,
			VisibleAuto:false,
			MuestraExpor:null,
			connection: {
				host: 'broker.emqx.io',
				port: 8084,
				//  port: 8083,
				endpoint: '/mqtt',
				clean: true, // 
				connectTimeout: 4000, // 
				reconnectPeriod: 4000, // 
				clientId: 'mqttjs_3be2c321',
				username: 'emqx_test',
				password: 'emqx_test',
			},
			subscription: {
				topic: 'topic/mqttx',
				qos: 0,
			},
			publish: {
				topic: 'sgcells/etiqueta',
				qos: 0,
				payload: '{ "msg": "Hello, I am browser." }',
			},
			receiveNews: '',
			qosList: [
				{ label: 0, value: 0 },
				{ label: 1, value: 1 },
				{ label: 2, value: 2 },
			],
			client: {
				connected: false,
			},
			subscribeSuccess: false,
			ServicosImprimir:[],
			SelectServicosImprimir:null,
			usuarios:null,
		}
	},
	muestraService: null,
	solicitudmuestraService:null,
	doctorService: null,
	usuarioService: null,
	servicioService: null,
	created() {
		this.doctorService = new DoctorService();
		this.muestraService = new MuestraService();
		this.solicitudmuestraService = new SolicitudMuestraService();
		this.usuarioService = new UsuarioService();		
		this.servicioService = new ServicioService();
	},
	mounted() {
		this.doctorService.getDoctores().then(data => this.doctores = data);
		this.servicioService.getServiciosPrecio().then(data => this.serviciosdisponibles = data.map(element => {
			return{
				id_servicio: element.id_servicio,
				nombre: element.nombre,
				precio: element.precio
			}
		}));
		this.muestraService.getMuestras().then(data =>{ 
			this.muestras = data;
			console.log(data);
			for(var i in this.muestras){
				var Nombre=this.muestras[i].paciente;
                  this.muestras[i].Name=Nombre+" "+data[i].pacienteApP+" "+data[i].pacienteApM;
                    
				}
			
			
		});
		this.permi=localStorage.getItem('permisos')
		if(this.permi ==null || this.permi == 'null' )
		{
			this.userdatapermisos=null;
		 	
				
		}else
		{
			if(this.permi != null || this.permi !="null")
			{
				this.userdatapermisos=JSON.parse(this.permi)
				for(var i in this.userdatapermisos)
				{
					if(22 == this.userdatapermisos[i].id_pantalla){
						this.VisibleAuto=true;
						// console.log(rep=true,item.label,this.userdata[i].nombre);
					}
				}			
			}		
		}

		this.usuarioService.getUsuarios().then(data => {
			this.usuarios = data			
		});
	},
	methods: {
		 openNewdescuento() { 
			this.submitted = false;
            this.descuentoDialog = true;
        },
		rowClicked(){ 
                
                for(var i in this.selectedMuestras){
					if(this.selectedMuestras[i].porc_descuento>0){
						this.autoriza=false;
					}else{
						this.autoriza=true;
					}
                } 
                       
        },
		forceRerender() {
      		this.estadoKey += 1;
    	},
		openNew() {
			this.muestra = {};
			this.submitted = false;
			this.muestraDialog = true;
			
		},
		hideDialog() {
			this.muestraDialog = false;
			this.submitted = false;
			this.descuentoDialog = false;
		},
		saveMuestra() {
			this.submitted = true;
           
			if (this.muestra.nombre.trim()) {
				if (this.muestra.id) {
					this.$set(this.muestras, this.findIndexById(this.muestra.id), this.muestra);
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Muestra actualizada', life: 3000});
				}
				else {
					this.muestra.id = this.createId();
					this.muestras.push(this.muestra);
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Muestra creada', life: 3000});
				}

				this.muestraDialog = false;
				this.muestra = {};
			}
		},
		editMuestra(muestra) {
			this.muestra = {...muestra};
			this.muestraDialog = true;
		},
		confirmDeleteMuestra(muestra) {
			this.muestra = muestra;
			this.deleteMuestraDialog = true;
		},
		deleteMuestra() {
			// this.muestras = this.muestras.filter(val => val.id !== this.muestra.id);
			this.useconver = Cookies.get("user");

			if(this.useconver != null || this.useconver !="null"){
				this.userdata=JSON.parse(this.useconver)												 
			}

			this.deleteMuestraDialog = false;  
			var FechaCan = new Date; 
			this.EstatusCambio.push({
				"id_muestra":this.muestra.id_muestra,
				"id_estatus":4,
				"motivocancelacion":this.cancelacion,
				"fecha_cancelacion":FechaCan.toISOString().slice(0,10),
				"motivo_descuento":this.muestra.motivo_descuento,
				"porc_descuento":this.muestra.porc_descuento,
				"id_usuario_mod":this.userdata[0].id_usuario, 
				"id_usuario_can":this.userdata[0].id_usuario,
			})
			 
			console.log(this.EstatusCambio);
			this.solicitudmuestraService.updateSolicitudMuestra(this.EstatusCambio[0]).then(data =>{
			console.log(data);document.location.reload();
			});
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Muestra eliminada', life: 3000});
			this.EstatusCambio=[];
			this.cancelacion=null;
			// document.location.reload();
			// document.location.reload();
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.muestras.length; i++) {
				if (this.muestras[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteMuestrasDialog = true;
		},
		confirmPrintSelected() {
			this.printMuestrasDialog = true;
			this.ServicosImprimir=[];
			for(var i in this.muestras)
			{
				if(this.selectedMuestras[0].id_muestra == this.muestras[i].id_muestra)
				{
						 this.ServicosImprimir.push({"Nombre":this.muestras[i].servicio});
				}
			}			
		},
		deleteSelectedMuestras() {
			console.log(this.muestras);
			// this.muestras = this.muestras.filter(val => !this.selectedMuestras.includes(val));
			// this.deleteMuestrasDialog = false;
			// this.selectedMuestras = null;
			// this.$toast.add({severity:'success', summary: 'Successful', detail: 'Muestras eliminadas', life: 3000});
		},
		printSelectedMuestras() {
	
		 
			this.ImpPost=[];
			this.publish.payload= this.etiquetasExteriores.toString()+','+this.etiquetasTubos.toString()+','+this.selectedMuestras[0].id_muestra.toString()+','+
			this.selectedMuestras[0].Name.toString()+','+this.selectedMuestras[0].pacienteFecha+','+this.SelectServicosImprimir.Nombre.toString()+','+this.selectedMuestras[0].doctor.toString();
			
			
 
			this.ImpPost.push({
                "folioMuestra": this.selectedMuestras[0].id_muestra ,
                "doctor":this.selectedMuestras[0].doctor,
                "paciente": this.selectedMuestras[0].Name,
                "fechanaciomiento":this.selectedMuestras[0].pacienteFecha,
                "exteriores": this.etiquetasExteriores,
                "tubos": this.etiquetasTubos,
                "tipo": "enviocelula"
            });
				 this.createConnection(); 
				  const { topic, qos, payload } = this.publish
					this.client.publish(topic, payload, qos, error => {
						 
						if (error) {
						console.log('Publish error', error)
						}
					})
					
			this.publish.payload='';	 
			this.printMuestrasDialog=false; 
			this.etiquetasTubos=1;
			this.etiquetasExteriores=1;
			this.SelectServicosImprimir=null;
                    
		},
		saveDescuento() {

			this.useconver = Cookies.get("user");

			if(this.useconver != null || this.useconver !="null"){
				this.userdata=JSON.parse(this.useconver)												 
			}

                this.submitted = true;
				this.descuentoDialog = false; 
				
				 for(var i in this.selectedMuestras){
					
					this.DescuentoPost.push({
						"id_muestra":this.selectedMuestras[i].id_muestra,
						"porc_descuento":this.porcdescuento,
						"id_doctor_descuento": this.doctordescuento.id_doctor,
						"motivo_descuento":this.motivodescuento, 
						"id_usuario_mod":this.userdata[0].id_usuario, 
					});  
					// console.log(this.DescuentoPost[i]);
					this.solicitudmuestraService.updateSolicitudMuestra(this.DescuentoPost[i]).then(data => {console.log(data)
					 document.location.reload();}); 
					
				} 
				
                this.DescuentoPost=[];
                      
		},
		hideDialog1() {
			this.confirmViewMu = false;
			 
		},
		confirmViewMuestra(muestra){
			this.displayedServices = false;
			this.confirmViewMu=true;
			this.FolioM = muestra.id_muestra;
			this.DoctorM = muestra.doctor;
			this.PacienteM = muestra.Name;
			this.NacimientoM = this.computedDatesFormat(muestra.pacienteFecha);
			this.AntecedentesM = muestra.antecendente;
			this.FechaDeseadaTomaM = this.computedDatesFormat(muestra.fecha_deseada_toma); 			 
			this.muestraService.getMuestrasID(muestra.id_muestra).then(data =>{
				this.servicios=data.data2;
				//this.serviciosSeleccionados = [...this.servicios];
				this.Padecimientos=data.data3;
			});

			// let Nombre='';
			
		},

		compareServices(){
			if(this.servicios.length === this.serviciosSeleccionados.length){
				for (let i = 0; i < this.serviciosSeleccionados.length; i++) {
					for (let j = 0; j < this.servicios.length; j++) {
						if(this.serviciosSeleccionados[i].id_servicio === this.servicios[j].id_servicio){
							break;
						}else if(j === this.servicios.length - 1){
							this.changeService = true;
							return;
						}
					}
				}
				this.changeService = false;
			}else{
				this.changeService = true;
			}
		},

		displayServices(){
			this.displayedServices =! this.displayedServices;
			this.serviciosSeleccionados = this.displayedServices? [...this.servicios]:[];
		},

		saveServicios() {

			var serviceDel = [...this.servicios];
			var serviceAdd = [];

			for (let i = 0; i < this.serviciosSeleccionados.length; i++) {

				if(serviceDel.length === 0){
					serviceAdd.push(this.serviciosSeleccionados[i]);
				}

				for (let j = 0; j < serviceDel.length; j++) {

					if(this.serviciosSeleccionados[i].id_servicio === serviceDel[j].id_servicio){
						serviceDel[j] = serviceDel[serviceDel.length - 1];
						serviceDel.pop();
						break;

					}else if(j === serviceDel.length - 1){
						serviceAdd.push(this.serviciosSeleccionados[i]);
					}
				}
			}

			for(let a in serviceAdd){        
                this.solicitudmuestraService.createSolicitudServicio(
					{
						"id_muestra":this.FolioM,
                    	"id_servicio":serviceAdd[a].id_servicio, 
                    	"precio":serviceAdd[a].precio,
					}
				);
            }

			for(let d in serviceDel){        
                this.solicitudmuestraService.deleteSolicitudServicio(
					{
						"id_muestra":this.FolioM,
                    	"id_servicio":serviceDel[d].id_servicio, 
					}
				);
            }
			document.location.reload();
            this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se actualizaron los servicios de la muestra', life: 3000});
			this.confirmViewMu=false;
            
		},

		/*createServicesColumn(){
			this.serviciostable = [...this.serviciosdisponibles];

			/*if(this.serviciosdisponibles.length != this.servicios.length){
				for (let i = 0; i < this.serviciostable.length; i++) {
					for (let j = 0; j < this.servicios.length; j++) {
						if(this.serviciostable[i].id_servicio === this.servicios[j].id_servicio){
							this.serviciostable[i].activo = true;
							break;
						}else if(j === this.servicios.length-1){
							this.serviciostable[i].active = false;
						}
					}
				}
			}
		},*/


		 formatCurrency(value) {
            // return value.toLocaleString('en-US', {style: 'currency',currency: 'USD'});
            let val = (value/1).toFixed(2).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		},
		Autodescuento(){
			 for(var i in this.selectedMuestras){
					if(this.selectedMuestras[i].porc_descuento>0){
						var useconver =Cookies.get( "user" );
						var userdata=JSON.parse(useconver);

						var d = new Date();
						// var Fecha1=new Date(d.toISOString().slice(0,10));
                        //     Fecha1.setDate(Fecha1.getDate()-1);
						this.AutoPos.push({
							"id_muestra":this.selectedMuestras[i].id_muestra,
							"id_usuario_autoriza":userdata[0].id_usuario,
							"fecha_autorizacion":this.computedDates(d),
							"porc_descuento":this.selectedMuestras[i].porc_descuento,
							"motivo_descuento":this.selectedMuestras[i].motivo_descuento,
						});
						console.log(this.AutoPos[i]);
						this.solicitudmuestraService.updateSolicitudMuestra(this.AutoPos[i]).then(data => {console.log(data)
						 	// document.location.reload();
						 });  						 
					}
				} 
				this.AutoPos=[];
				this.AutorizardesDialog=false;
				 document.location.reload() ; 
		},
		 computedDates (value) {
                return moment(value).format('YYYY-MM-DD')
			},
		computedDatesFormat (value) {
                return moment(value).format('DD/MM/YYYY')
			},
		computedDatesFormatGet (value) {
                return moment(value).format('DD-MM-YYYY')
			},
		ShowAutoDialog(){
			this.AutorizardesDialog=true;
		},
		hideAutoDialog(){
			this.AutorizardesDialog=false;
		},
		download : function() {
			this.muestraService.getMuestrasExport().then(data => {
			this.MuestraExpor = data;

			for(var i in  this.MuestraExpor)
			{ 
				for(var can in this.usuarios){
					if(data[i].id_usuario_can == this.usuarios[can].id_usuario)
					{
						this.MuestraExpor[i].id_usuario_can=this.usuarios[can].nombre+" "+this.usuarios[can].apellido_paterno+" "+this.usuarios[can].apellido_materno;
					}					
				}

				for(var reg in this.usuarios){
					if(data[i].id_usuario_reg == this.usuarios[reg].id_usuario)
					{
						this.MuestraExpor[i].id_usuario_reg=this.usuarios[reg].nombre+" "+this.usuarios[reg].apellido_paterno+" "+this.usuarios[reg].apellido_materno;
					}					
				}

				for(var mod in this.usuarios){
					if(data[i].id_usuario_mod == this.usuarios[mod].id_usuario)
					{
						this.MuestraExpor[i].id_usuario_mod=this.usuarios[mod].nombre+" "+this.usuarios[mod].apellido_paterno+" "+this.usuarios[mod].apellido_materno;
					}					
				}

				for(var aut in this.usuarios){
					if(data[i].autorizadopor == this.usuarios[aut].id_usuario)
					{
						this.MuestraExpor[i].autorizadopor=this.usuarios[aut].nombre+" "+this.usuarios[aut].apellido_paterno+" "+this.usuarios[aut].apellido_materno;
					}					
				}			
				this.MuestraExpor[i].id_estatus=	this.MuestraExpor[i].estatusmuestra;
			}
			// this.MuestraExpor.splice(12);

				if(this.MuestraExpor != null){
				const data = XLSX.utils.json_to_sheet(this.MuestraExpor)
				const wb = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(wb, data, 'data')
				XLSX.writeFile(wb,'Muestras.xlsx')
				}
			});
	
		},
		createConnection() {
			const { host, port, endpoint, ...options } = this.connection
			const connectUrl = `wss://${host}:${port}${endpoint}`
            // const connectUrl = `ws://${host}:${port}${endpoint}`
			try {
				this.client = mqtt.connect(connectUrl, options)
				
				console.log(this.client);
			} catch (error) {
				console.log('mqtt.connect error', error)
			}
			this.client.on('connect', () => {
				console.log('Connection succeeded!')
			})
			this.client.on('error', error => {
				console.log('Connection failed', error)
			})
			this.client.on('message', (topic, message) => {
				this.receiveNews = this.receiveNews.concat(message)
				console.log(`Received message ${message} from topic ${topic}`)
			})
		},	
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.muestra-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .muestra-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.muestra-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-activo {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-inactivo {
		background: #FFCDD2;
		color: #C63737;
	}
}

.btn {
    background-color: transparent;
    color: darkslategrey;
    text-align: center;
    background: none;
    font-size: 20px;
    padding: 0;
    border: none;
}
.btn:hover{
	cursor: pointer;
}
</style>
